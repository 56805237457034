"use client";

import { Button, TextField } from "@mui/material";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
const Search = ({
  page
}: {
  page?: string;
}) => {
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const {
    replace
  } = useRouter();
  function handleSearch(event: any) {
    event.preventDefault();
    const term = event.target[0].value; // search term
    const params = new URLSearchParams(searchParams);
    params.set("page", "1");
    if (term) {
      params.set("search", term);
    } else {
      params.delete("search");
    }
    if (page) {
      replace(`${page}/${pathname}?${params.toString()}`);
    } else {
      replace(`${pathname}?${params.toString()}`);
    }
  }
  return <form onSubmit={handleSearch} style={{
    width: "80%",
    display: "flex",
    alignItems: "center"
  }} data-sentry-component="Search" data-sentry-source-file="Search.tsx">
      <TextField placeholder="Search" variant="outlined" name="search" defaultValue={searchParams.get("search")?.toString()} sx={{
      width: "100%",
      "& .MuiInputBase-input": {
        borderRadius: 4,
        position: "relative",
        backgroundColor: "#EFF6E0",
        border: "1px solid",
        borderColor: "#598392",
        fontSize: 16,
        width: "100%",
        padding: "10px 12px"
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#598392",
        borderRadius: 4
      }
    }} data-sentry-element="TextField" data-sentry-source-file="Search.tsx" />
      <Button data-umami-event="search-button" variant="outlined" type="submit" sx={{
      color: "#598392",
      border: "1px solid #598392",
      ":hover": {
        border: "1px solid #AEC3B0"
      },
      padding: "8px 15px",
      marginLeft: 2
    }} data-sentry-element="Button" data-sentry-source-file="Search.tsx">
        Search
      </Button>
    </form>;
};
export default Search;