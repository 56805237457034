"use client";

import { createClient } from "@/utils/supabase/client";
import { Box } from "@mui/material";

/** used to wrap around link clicks to handle click tracking */
const ClickWrapper = ({
  id,
  fieldName,
  type,
  children
}: {
  id: string;
  /** field to increment */
  fieldName: string;
  type: "creator" | "post";
  children: React.ReactNode;
}) => {
  /** update downloads count for album */
  const supabase = createClient();
  return <Box onClick={async () => {
    type === "creator" ? await supabase.rpc("increment_creator", {
      x: 1,
      creator_param: id,
      field_name: fieldName
    }) : await supabase.rpc("increment", {
      x: 1,
      post_id_param: id,
      field_name: fieldName
    });
  }} data-sentry-element="Box" data-sentry-component="ClickWrapper" data-sentry-source-file="ClickWrapper.tsx">
      {children}
    </Box>;
};
export default ClickWrapper;