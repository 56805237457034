"use client";

import { useState } from "react";
import Image from "next/image";
interface ImageWithFallbackProps {
  /** key is required to trigger rerender */
  key: string;
  src: string;
  fallbackSrc?: string;
  alt: string;
  [key: string]: any; // Allow additional props
}
const ImageWithFallback = ({
  key,
  src,
  fallbackSrc = "/black-image.jpeg",
  alt,
  ...rest
}: ImageWithFallbackProps) => {
  const [imgSrc, setImgSrc] = useState(src);
  return <Image {...rest} key={key} alt={alt} src={imgSrc} onError={() => {
    setImgSrc(fallbackSrc);
  }} data-sentry-element="Image" data-sentry-component="ImageWithFallback" data-sentry-source-file="ImageWithFallback.tsx" />;
};
export default ImageWithFallback;